/*******************************
         Site Overrides
*******************************/

.ui.primary.button {
	border-radius: 0;
}

.ui.button {
	border-radius: 0;
}

.ui.basic.buttons .button, .ui.basic.button {
	border-radius: 0;
}