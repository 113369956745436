/*******************************
         Site Overrides
*******************************/

.ui.header {
	font-weight: 400;
}

.ui.header .sub.header {
	color: #869AB8;
	font-weight: 300;
}